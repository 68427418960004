<template>

    <b-card-code
      :title='$t(`message.pendingoffers`)'
      no-body
    >
    <b-modal id="modal-ferror" title="Something went wrong" v-model="modalAction.show" ok-only centered @hide="fetchErr(modalAction.redirect)">
        <p class="my-4">{{$t(`message.erroroccured`)}}</p>
    </b-modal>

      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
  
          <!-- sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="2"
            label-for="sortBySelect"
            class="mr-1 mb-md-0 align-items-center"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
  
          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 align-items-center"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
                debounce=500
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
  
  
    <b-card-code
      title=""
      no-body
    >
      <b-table
        striped
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :busy="isBusy"
        >
        <template #table-busy>
          <div class="d-flex align-items-center justify-content-around my-2">
          <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
          <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
          <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
          </div>
        </template>
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>
         <!-- offer name -->
         <template #cell(offer_name)="data">
          <b-button size="sm" variant="outline-success"  v-b-modal="'mo_'+data.item.policyID">{{ data.item.offer_name }} Offer</b-button>
          <template v-if="data.item.issue_policy">
            <b-modal ok-only :id="'mo_'+data.item.policyID" :title="data.item.offer_name" @show="fetchResults(data.item.from_app, data.item.issue_policy.residence, data.item.issue_policy.destination, data.item.issue_policy.via)">
              <b-list-group v-if="data.item.from_app">
                <b-list-group-item>Residence: <b-badge variant="success">{{ data.item.issue_policy.res_name || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Destination: <b-badge variant="success">{{ data.item.issue_policy.des_name || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Start Date: <b-badge variant="success">{{ data.item.issue_policy.start_date || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Enda Date: <b-badge variant="success">{{ data.item.issue_policy.end_date || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Duration: <b-badge variant="success">{{ data.item.issue_policy.days || '-' }} days</b-badge></b-list-group-item>
                <b-list-group-item>Number of Travellers: <b-badge variant="success">{{ data.item.persons || '-' }}</b-badge></b-list-group-item>
              </b-list-group>
              <b-list-group ref="listgroupleg" class="showListGroup" v-else>
                <b-list-group-item>Residence: <b-badge variant="success">{{ residence_legacy || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Destination: <b-badge variant="success">{{ destination_legacy || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Via: <b-badge variant="success">{{ via_legacy || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Start Date: <b-badge variant="success">{{ data.item.issue_policy.sdate || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Enda Date: <b-badge variant="success">{{ data.item.issue_policy.edate || '-' }}</b-badge></b-list-group-item>
                <b-list-group-item>Number of Travellers: <b-badge variant="success">{{ data.item.persons || '-' }}</b-badge></b-list-group-item>
              </b-list-group>
            </b-modal>
          </template>
        </template>
        <!-- download offer -->
        <template #cell(download_offer)="data">
          <b-dropdown :id="'dropdown-offer'+data.item.policyID" size="sm" text="Language" class="m-md-2">
            <b-dropdown-item target='_blank' :href="'https://quote.safetravel.today/offer.php?offerID='+data.item.policyID+'&lang=gr'"><sub>GR</sub> Greek </b-dropdown-item>
            <b-dropdown-item target='_blank' :href="'https://quote.safetravel.today/offer.php?offerID='+data.item.policyID+'&lang=en'"><sub>EN</sub> English </b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- issue policy -->
        <template #cell(issue_policy)="data">
          <b-dropdown block variant="success" :id="'dropdown-quote'+data.item.policyID" size="sm" text="Issue" class="m-md-2" @show="createQuote(data.item.from_app, data.item.issue_policy)">
              <b-dropdown-item variant="success" to='forms/quotation' v-if="infoQuote">{{data.item.offer_name}} Quotation</b-dropdown-item>
              <b-dropdown-item variant="danger" v-else>Quotation for this offer is not available</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

        <!-- buttons -->
      <b-card-body class="demo-inline-spacing pt-0" v-if="items.length">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="downloadExcel(items,'Offers.xlsx')"
        >
          Offers Excel
        </b-button>
      </b-card-body>
  
      <template #code>
        {{ codeRowSelectSupport }}
      </template>
    </b-card-code>
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
  
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 align-items-center"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
  
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 align-items-center"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
  
      <template #code>
        {{ codeKitchenSink }}
      </template>
    </b-card-code>
  </template>
  
  <script>
  import { cookie, token_id, products, downloadExcelMixin } from '@/products';
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BAlert, BSpinner, BListGroup, BListGroupItem
  } from 'bootstrap-vue'
  import { codeKitchenSink } from '../table/bs-table/code'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import { codeRowSelectSupport } from '../table/bs-table/code'
  
  export default {
    mixins: [downloadExcelMixin],
    components: {
      BCardCode,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BAlert,
      BSpinner,
      BListGroup,
      BListGroupItem,
      vSelect
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        modalAction: {
            show: false,
            redirect: false,
        },
        showListGroup: true,
        residence_legacy: '-',
        destination_legacy: '-',
        via_legacy: '-',
        infoQuote: false,
        loadingDrop: 'Loading...',
        processing: false,
        isBusy: true,
        modes: ['multi', 'single', 'range'],
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'date',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'policyID', label: 'Policy ID', sortable: true },
          { key: 'offer_name', label: 'Offer Name' },
          { key: 'policy_details', label: 'Policy Details', sortable: true, },
          { key: 'date_of_travel', label: 'Date of Travel', sortable: true, tdClass: 'text-nowrap' },
          { key: 'persons', label: 'Persons', sortable: true },
          { key: 'download_offer', label: 'Download Offer' },
          { key: 'issue_policy', label: 'Issue Policy' },
          { key: 'date', label: 'Date', sortable: true, tdClass: 'text-nowrap' },
        ],
        /* eslint-disable global-require */
        items: [],
        /* eslint-disable global-require */
        status: [{
          1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
        },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
        codeKitchenSink,
        selectMode: 'multi',
        selected: [],
        codeRowSelectSupport,
        products,
        token_id,
        cookie
      }
    },
    async created() {
      await this.getOffers(0,50);
      await this.getOffers(50,100);
      await this.getOffers(100,200);
      await this.getOffers(200,400);
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
    },
    methods: {
      fetchErr(redirect) {
        if (redirect) {
            window.location.href = '/landing';
        }
      },
      async fetchResults(app, residence, destination, via) {
        if (!app) {
          await this.fetchR('get_residence', 'residence', residence, 'rs_m');
          await this.fetchR('get_destination', 'destination', destination, 'ds_m');
          await this.fetchR('get_via', 'via', via, 'via_m');
        }
      },
      async fetchR(url, name, param, updateCl) {
        const data = {
          token : this.token_id,
          [name]: [name] == 'via' ? String(param) : Number(param),
          jwt: this.cookie
        }
        try {
          await fetch("https://api-quote.safetravel.today/v2/app/"+url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=> {
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            if (updateCl == 'rs_m') {
              this.residence_legacy = res.Country_GR ?? '-'
            } else if (updateCl == 'ds_m') {
              this.destination_legacy = res.Country_EN ?? '-'
            } else if (updateCl == 'via_m') {
              this.via_legacy = res ?? '-'
            }
          })
        } catch(err) {
          console.warn(err)
        }
        finally {
          // remove blur
          setTimeout(() => {
            this.$refs.listgroupleg.classList.remove('showListGroup')
          }, 800);
        }
      },
      createQuote(from_app, info) {
        this.infoQuote=false;
        // remove older quote info items
        if (localStorage.getItem("quote_info_app") !== null) {
          localStorage.removeItem("quote_info_app");
        }
        if (localStorage.getItem("quote_info") !== null) {
          localStorage.removeItem("quote_info");
        }
        if (info) {
          if (from_app) {
            this.infoQuote=true;
            // create quote_info_app
            localStorage.setItem('quote_info_app', JSON.stringify(info));
          } else {
            this.infoQuote=true;
            // create quote_info
            localStorage.setItem('quote_info', JSON.stringify(info));
          }
        }
      },
      async getOffers(start_limit, end_limit) {
        const url= {
            prod: 'https://api-quote.safetravel.today/v2/app/get_pending_offers'
        };

        const data = {
            token: this.token_id,
            start_limit: start_limit,
            end_limit: end_limit,
            jwt: this.cookie
        }
        try {
            await fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((res)=> {
              if (res === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              res.forEach((r)=> {
                const offer_products = [];
                for (const op in r?.offer_products) {
                  offer_products.push(products[op]);
                }
                this.items.push({
                    policyID: r?.policyID ?? '-',
                    offer_name: r?.group_policy_name ?? '-',
                    policy_details: offer_products.join(', ') ?? '-',
                    date_of_travel: r?.date_of_travel ?? '-',
                    persons: r?.number_of_insured_customers ?? '-',
                    download_offer: '',
                    issue_policy: r?.quote_info ?? null,
                    date: r?.r_timestamp ?? '-',
                    from_app: r?.app ?? null
                })
              });
            })
            .then(() => {
              this.totalRows = this.items.length;
              this.isBusy = false;
              this.sortBy = 'date';
            })
        } catch (error) {
            // TypeError: Failed to fetch
            console.warn('There was an error to get offers', error);
            this.modalAction = {
                show: true,
                redirect: true,
            }
        }
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    },
  }
  </script>
  <style>
    .showListGroup {
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }
  </style>